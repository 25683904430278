<template>
    <div class="histories">
        <PageNavBar title="历史记录" :goBack="goBackAndRemoveKeepAlive"/>

        <article-list :articles="article_list"></article-list>
    </div>
</template>

<script>
import PageNavBar from '../../components/PageNavBar'
import ArticleList from '../../components/article-list'
import { keepAliveMixin } from '../../mixins'
import common from '../../utils/common'
import { getArticlesByIds } from '../../api/article'

export default {
    name: 'HistoryPage',
    mixins: [keepAliveMixin],
    components: {
        PageNavBar,
        ArticleList
    },
    props: {
    },
    setup() {
    },
    mounted() {
    },
    data() {
        return {
            article_list: []
        }
    },
    created() {
        let historyIds = common.getArticleHistory()
        this.getArticles(historyIds)
    },
    methods: {
        async getArticles(ids) {
            this.article_list = []
            let list = await getArticlesByIds(ids)

            if (list && list.length > 0) {
                list.forEach(item => { this.article_list.push(item) });
            }
        }
    }
}
</script>

<style lang="stylus" scoped>
</style> 